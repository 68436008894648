import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const Footer = () => {
    const isMobile = useMediaQuery({
        query: '(max-width:767px)',
    });
    return (
        <Wrapper>
            <Container>
                <h1>DEPth 명지대학교 프로젝트형 IT 동아리</h1>
                <h2>COPYRIGHT©2023.DEPth All rights reserved</h2>
                <ButtonContainer>
                    <CopyToClipboard
                        text="2022depth@gmail.com"
                        onCopy={() => alert('클립보드에 복사되었습니다.')}
                    >
                        <svg
                            width={isMobile ? 23 : 30}
                            height={isMobile ? 23 : 30}
                            viewBox="0 0 31 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M30.9406 7.15845L20.0673 18.0317C18.8549 19.241 17.2124 19.9202 15.5 19.9202C13.7876 19.9202 12.1451 19.241 10.9327 18.0317L0.0594167 7.15845C0.0413333 7.36253 0 7.54724 0 7.75003V23.25C0.00205098 24.9623 0.683139 26.6038 1.89387 27.8145C3.1046 29.0252 4.74611 29.7063 6.45833 29.7084H24.5417C26.2539 29.7063 27.8954 29.0252 29.1061 27.8145C30.3169 26.6038 30.9979 24.9623 31 23.25V7.75003C31 7.54724 30.9587 7.36253 30.9406 7.15845Z"
                                fill="white"
                            />
                            <path
                                d="M18.2409 16.2052L30.0389 4.40583C29.4674 3.45816 28.6613 2.67376 27.6984 2.12828C26.7355 1.5828 25.6483 1.29465 24.5416 1.29163H6.45827C5.3516 1.29465 4.26438 1.5828 3.30147 2.12828C2.33857 2.67376 1.53247 3.45816 0.960938 4.40583L12.759 16.2052C13.487 16.9302 14.4725 17.3373 15.4999 17.3373C16.5273 17.3373 17.5129 16.9302 18.2409 16.2052Z"
                                fill="white"
                            />
                        </svg>
                    </CopyToClipboard>
                    <a
                        href="https://instagram.com/depth_mju.co.kr?igshid=YmMyMTA2M2Y="
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        <svg
                            width={isMobile ? 23 : 30}
                            height={isMobile ? 23 : 30}
                            viewBox="0 0 38 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_98_1052)">
                                <path
                                    d="M19.0062 8.91919C13.8072 8.91919 9.59814 13.1328 9.59814 18.3272C9.59814 23.5263 13.8118 27.7353 19.0062 27.7353C24.2052 27.7353 28.4143 23.5217 28.4143 18.3272C28.4143 13.1282 24.2006 8.91919 19.0062 8.91919ZM19.0062 24.4338C15.6313 24.4338 12.8997 21.7006 12.8997 18.3272C12.8997 14.9539 15.6329 12.2207 19.0062 12.2207C22.3795 12.2207 25.1127 14.9539 25.1127 18.3272C25.1143 21.7006 22.3811 24.4338 19.0062 24.4338Z"
                                    fill="white"
                                />
                                <path
                                    d="M26.5594 0.116151C23.186 -0.0412105 14.8306 -0.0335716 11.4542 0.116151C8.4873 0.255178 5.87022 0.971706 3.76036 3.08157C0.234246 6.60768 0.68494 11.3591 0.68494 18.3273C0.68494 25.4589 0.287718 30.1003 3.76036 33.573C7.30022 37.1113 12.1204 36.6484 19.0061 36.6484C26.0705 36.6484 28.5088 36.653 31.0067 35.6859C34.403 34.3674 36.9666 31.3317 37.2172 25.8791C37.376 22.5042 37.3669 14.1503 37.2172 10.7739C36.9147 4.3374 33.4604 0.433928 26.5594 0.116151ZM31.899 31.24C29.5874 33.5516 26.3806 33.3453 18.9617 33.3453C11.3229 33.3453 8.25966 33.4584 6.02452 31.2171C3.45022 28.655 3.91619 24.5407 3.91619 18.3028C3.91619 9.86184 3.04994 3.78282 11.5215 3.34893C13.4679 3.28018 14.0408 3.25726 18.9404 3.25726L19.0091 3.30309C27.1506 3.30309 33.5383 2.45059 33.9217 10.9206C34.0088 12.8532 34.0287 13.4338 34.0287 18.3257C34.0272 25.876 34.1708 28.9575 31.899 31.24Z"
                                    fill="white"
                                />
                                <path
                                    d="M28.7869 10.7463C30.001 10.7463 30.9853 9.76202 30.9853 8.54784C30.9853 7.33365 30.001 6.34937 28.7869 6.34937C27.5727 6.34937 26.5884 7.33365 26.5884 8.54784C26.5884 9.76202 27.5727 10.7463 28.7869 10.7463Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_98_1052">
                                    <rect
                                        width="36.6667"
                                        height="36.6667"
                                        fill="white"
                                        transform="translate(0.666748)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a
                        href="https://open.kakao.com/o/sDxpKehh"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        <svg
                            width={isMobile ? 23 : 30}
                            height={isMobile ? 23 : 30}
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_98_1056)">
                                <path
                                    d="M14.7894 14.2175L13.7322 17.1982H15.8451L14.7894 14.2175Z"
                                    fill="white"
                                />
                                <path
                                    d="M18.6668 1.52783C8.54225 1.52783 0.333496 7.95672 0.333496 15.8874C0.333496 21.0146 3.76488 25.5139 8.92725 28.0531C6.94266 34.8517 6.89072 34.8823 7.22683 35.0794C7.6485 35.3253 8.19544 35.0717 15.4111 30.0209C16.4684 30.1691 17.5561 30.247 18.6668 30.247C28.7914 30.247 37.0002 23.8181 37.0002 15.8874C37.0002 7.95672 28.7914 1.52783 18.6668 1.52783ZM9.58877 19.8963C9.58877 20.4494 9.11364 20.9001 8.53155 20.9001C7.94947 20.9001 7.47433 20.4494 7.47433 19.8963V13.6538H5.82433C5.25141 13.6538 4.78697 13.1924 4.78697 12.6256C4.78697 12.0588 5.24988 11.5959 5.8228 11.5959H11.2388C11.8117 11.5959 12.2761 12.0573 12.2761 12.6241C12.2761 13.1909 11.8102 13.6538 11.2388 13.6538H9.58877V19.8963ZM17.8052 20.8863C16.7052 20.8863 16.9084 20.0231 16.4011 19.0606H13.176C12.6734 20.0155 12.8704 20.8863 11.772 20.8863C10.7132 20.8878 10.5268 20.2431 10.8492 19.2501L13.3792 12.6348C13.5579 12.1306 14.0988 11.6127 14.7878 11.5974C15.4784 11.6127 16.0207 12.1306 16.1979 12.6348C17.8678 17.8139 20.2527 20.8878 17.8052 20.8863ZM23.8231 20.7458H20.4299C18.6592 20.7458 19.7363 18.3548 19.417 12.647C19.417 12.068 19.9013 11.5959 20.4971 11.5959C21.0929 11.5959 21.5772 12.0664 21.5772 12.647V18.8192H23.8246C24.3838 18.8192 24.8375 19.2516 24.8375 19.7817C24.836 20.3134 24.3822 20.7458 23.8231 20.7458ZM32.1021 19.9544C32.0639 20.2309 31.9157 20.4799 31.6911 20.648C30.2321 21.7495 29.1535 18.5381 27.7296 17.1784L27.3629 17.5435V19.8352C27.3629 20.4157 26.8893 20.8863 26.3042 20.8863C25.7206 20.8863 25.247 20.4157 25.247 19.8352V12.647C25.247 12.068 25.7206 11.5959 26.3042 11.5959C26.8878 11.5959 27.3614 12.0664 27.3614 12.647V14.9051C29.3124 13.4414 30.394 10.8259 31.627 12.0496C32.837 13.2505 30.3314 14.3016 29.294 15.7499C31.7446 19.1294 32.2106 19.2134 32.1021 19.9544Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_98_1056">
                                    <rect
                                        width="36.6667"
                                        height="36.6667"
                                        fill="white"
                                        transform="translate(0.333496)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </ButtonContainer>
            </Container>
        </Wrapper>
    );
};

export default Footer;

const Wrapper = styled.div`
    bottom: 0;
    height: 20.6rem;
    position: relative;
    width: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h1,
    h2 {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 350;
        font-size: 1.45rem;
        display: flex;
        align-items: center;
        text-align: center;
        color: white;
        margin-bottom: 1rem;
    }
    svg {
        margin-right: 4rem;
    }
`;

const Container = styled.div`
    margin-left: 10.9rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 2.4rem;

    div:first-child {
        margin-top: -1.6rem;
    }
`;
